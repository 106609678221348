.adminPage {
  display: flex;
  flex-direction: row;
}

.userRequestPanel {
  width: 30vw;
  height: 90vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.chatPanel {
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.messageBox {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fafafa;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 8px;
  margin: 0.3rem;
}

.requestDetails {
  display: flex;
  flex-direction: column;
}

.sentMessage {
  align-self: flex-end;
}

.receievedMessage {
  align-self: flex-start;
}

.messagePanel {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
}
.messageWrap {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.addMessagePanel {
  display: flex;
}

.messageInput {
  font-size: 1rem;
  padding: 0.3rem;
  width: 40vw;
  border: 1px solid #a760ff;
  border-radius: 8px;
}
