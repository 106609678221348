.task-modal-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.add-task-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.add-project-modal {
    padding: 2rem;
  z-index: 10;
  position: absolute;
  width: 40vw;
  background-color: #fff;
  border-radius: 8px;
}
.center-add-task {
  top: 10vh;
  left: 28vw;
}
.messageBox {
  height: 20vw;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
