.avatar {
  text-align: center;
  border-radius: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.userCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem;
  background-color: #fafafa;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.userCardName {
  margin: 0.2rem;
}
