.headerBar {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
}

.avatarRow {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.avatar {
  text-align: center;
  border-radius: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.name {
  font-weight: normal;
}

.nameColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p,
h3 {
  margin: 1px;
}
