.messageBox {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fafafa;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 8px;
  margin: 0.3rem;
}

.sentMessage {
  align-self: flex-end;
}

.receievedMessage {
  align-self: flex-start;
}
